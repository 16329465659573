@import "variables";

.ant-modal-content {
  border-radius: 12px !important;
}

.ant-modal-close-x {
  position: absolute;
  top: -20px;
  right: -20px;
  background: $close-color;
  border-radius: 50%;
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 37px;
  font-size: 13px;
}

.ant-select-item-option-content,.ant-select-selection-item {
  text-transform: capitalize;
}

.ant-modal-confirm {
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      color: $primary-color;
      font-size: 18px;
      font-weight: bold;
    }
    .ant-modal-confirm-content {
      margin-top: 1rem;
      font-size: 16px;
      text-align: center;
    }
  }
  .ant-modal-confirm-btns {
    float: none;
    text-align: center;
  }
}

// Tabs
.ant-tabs-nav:before{
  border: unset !important;
}
.ant-tabs-ink-bar{
  display: none !important;
}

.ant-tabs-tab{
  padding: 6px 20px !important;
  .ant-tabs-tab-btn{
    color: #646464 !important;
  }
  &:hover{
    .ant-tabs-tab-btn{
      color: $secondary-color !important;
    }
  }
}
.ant-tabs-tab-active{
  border: 1px solid $secondary-color;
  border-radius: 12px;
  .ant-tabs-tab-btn{
    color: $secondary-color !important;
  }
}