@import "src/styles/variables";

.clinical-staff-dashboard {
  position: relative;
  .clinical-staff-dashboard__search{
    position: absolute;
    right: 10px;
    z-index: 2;
  }

  .ant-tabs-nav::before{
    display: none;
  }

  .ant-tabs-ink-bar{
    display: none
  }

  .ant-tabs-tab{
    color: #4F4F4F !important;
    padding: 6px 18px;
    border-radius: 12px;
  }

  .ant-tabs-tab-active{
    border: 1px solid $secondary-color;
    .ant-tabs-tab-btn {
      color: $secondary-color !important;
      font-weight: bold;
    }
  }

  .ant-select-selector{
    border: unset !important;
    height: 40px !important;
    box-shadow: unset !important;
    background: transparent !important;
    border-radius: unset !important;
    padding-left: unset !important;
  }
}