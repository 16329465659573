@import "src/styles/variables";

.hospital-admin-dashboard {
    p.text-capitalize:hover {
        text-decoration: underline;
    }
}

.dashboard-preview {

    .image-preview {
        border-radius: 50px;
        width: 50px;
    }

    b {
        font: normal normal bold 16px/22px Nunito;
        text-transform: capitalize;
    }

    div {
        font: normal normal normal 14px/19px Nunito;
        letter-spacing: 0px;
        color: #4F4F4F;
    }
}